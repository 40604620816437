.sf-input {
  &[type="text"] {
    display: flex;
    min-height: 48px;
    padding: 12px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid theme(borderColor.blueMood);
    background: theme(backgroundColor.white);
    color: theme(textColor.cursedBlack);

    &:disabled {
      background: theme(backgroundColor.disabled);
      color: theme(textColor.blueRinse);
    }

    &:focus {
      border: 1px solid theme(borderColor.deepSapphire);
      box-shadow: 0px 0px 0px 4px rgba(0, 102, 214, 0.2);
    }
  }
}
