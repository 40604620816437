@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,600;0,7..72,800;1,7..72,300;1,7..72,800&display=swap");

@font-face {
  font-family: "Allison";
  src: url("../public/fonts/Allison-Regular.ttf");
}

@font-face {
  font-family: "lexend";
  src: url("../public/fonts/LexendDeca-Regular.ttf");
}

@font-face {
  font-family: "lexend";
  src: url("../public/fonts/LexendDeca-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "lexend";
  src: url("../public/fonts/LexendDeca-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "lexend";
  src: url("../public/fonts/LexendDeca-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Jost";
  src: url("../public/fonts/Jost-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Playfiar";
  src: url("../public/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

.literata-font {
  font-family: "Literata", serif;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  margin-top: 15px !important;
  top: 35% !important;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
}

.carousel.carousel-slider .control-arrow {
  background: yellow !important;
  height: 50px !important;
  width: 50px;
  border-radius: 100%;
  position: absolute;
  margin-top: 15px !important;
  top: 35% !important;
}

/* safari only selector */
@supports selector(:nth-child(1 of x)) {
  select {
    -webkit-appearance: none;
  }
}

@media (min-height: 325px) {
  .banner-image {
    height: 4rem;
  }
}

@media (min-height: 568px) {
  .banner-image {
    height: 5rem;
  }
}

@media (min-height: 667px) {
  .banner-image {
    height: 12rem;
  }
}

/* 
  calc required for mobile viewports https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ 
*/
.banner-height {
  height: 100vh;
  height: var(--vh, 1vh);
}

html * {
  font-family: "Jost", sans-serif;
}

.loader-backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #f1f2f4;
  opacity: 0.7;
}

.loader {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.synthesis-none {
  font-synthesis: none !important;
}

.page-background {
  background: linear-gradient(180deg, #def6fb 0%, #f9feff 66.15%);
  align-items: center;
  display: flex;
  flex-flow: column;
}

@layer components {
  @import "./css/components.css";
}
