.buttonTransition-enter {
  opacity: 0;
}
.buttonTransition-exit {
  opacity: 1;
}
.buttonTransition-enter-active {
  opacity: 1;
}
.buttonTransition-exit-active {
  opacity: 0;
}
.buttonTransition-enter-active,
.buttonTransition-exit-active {
  transition: opacity 200ms;
}
