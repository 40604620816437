.sf-box {
  width: 100%;
  padding: 32px 24px;
  border: 1px solid theme(borderColor.aquarellePurple);
  border-bottom: theme(borderWidth.0);
  background-color: theme(backgroundColor.white);

  & > div {
    & > div {
      &:not(.scroll-down) {
        padding: 0;
      }

      label {
        color: theme(textColor.deepSapphire);
        font-size: 16px;
      }
    }
  }

  a {
    color: theme(textColor.maldives);
    font-size: 18px;
    line-height: 160%;
    text-decoration-line: underline;
  }

  h6 {
    font-family: "Playfiar";
    color: theme(textColor.deepSapphire);
    font-size: 28px;
    font-weight: 500;
    padding-bottom: 8px;

    &.section-title {
      font-family: "Jost";
      color: theme(textColor.deepSapphire);
      font-size: 19px;
      font-weight: 500;
      line-height: 160%;
    }
  }

  p {
    color: theme(textColor.raftsman);
    font-size: 18px;
    line-height: 160%;
  }

  .sub-container {
    display: flex;
    flex-flow: column;
    padding: 24px 0;
    width: 100%;
    gap: 16px;

    &.compact {
      padding: 16px 0;
    }

    label {
      font-weight: theme(fontWeight.semibold);
    }

    input[type="radio"] {
      & + label,
      & + div > label {
        font-weight: theme(fontWeight.normal);
      }
    }
  }

  .action {
    flex-direction: column;
    flex: 1 0 0;

    button {
      font-family: "Jost";
      min-height: 48px;
      padding: 16px 24px;
      align-items: center;
      gap: 16px;
      width: 100%;
      justify-content: space-around;

      &.bg-yellow {
        color: theme(textColor.deepSapphire);
        font-weight: theme(fontWeight.bold);
        font-size: 16px;
      }

      div {
        display: flex;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    width: 24px;
    height: 24px;

    + label {
      max-width: 90%;
    }
  }

  label {
    padding-left: theme(padding.2);
    color: theme(textColor.raftsman);
    font-size: 16px;
    font-style: normal;
    line-height: 160%;
  }
}

.sf-box-deep {
  p {
    color: theme(textColor.raftsman);
    padding-bottom: theme(padding.2);
  }

  strong {
    color: theme(textColor.deepSapphire);
  }

  .call-out {
    color: #252427;
    font-size: 20px;
  }
}

.signature-box {
  width: 100%;
  gap: 8px;

  > div {
    color: theme(textColor.deepSapphire);
    font-size: 16px;
    font-weight: theme(fontWeight.semibold);
    line-height: 24px;
  }

  label {
    font-weight: theme(fontWeight.medium);
  }

  .sign {
    border-radius: 8px;
    padding: 32px 12px 8px 12px;
    border-width: theme(borderWidth.DEFAULT);
    border-color: theme(borderColor.blueRinse);
  }

  .sign-group {
    border-bottom: 1px dashed #b8bdc6;

    input {
      font-family: Allison;
      font-size: 48px;
      line-height: 31px;
      border: theme(borderWidth.0);
      border-radius: theme(borderRadius.none);
      padding-left: theme(padding.4);
      padding-bottom: theme(padding.0);
    }
  }
}

.scroll-down {
  box-shadow:
    0px -54px 15px 0px rgba(0, 0, 0, 0),
    0px -35px 14px 0px rgba(0, 0, 0, 0.01),
    0px -20px 12px 0px rgba(0, 0, 0, 0.02),
    0px -9px 9px 0px rgba(0, 0, 0, 0.04),
    0px -2px 5px 0px rgba(0, 0, 0, 0.05);
}

@media screen(lg) {
  .sf-box {
    width: 600px;
    padding: 40px;
    border-radius: 16px;
    border-bottom: theme(borderWidth.DEFAULT);
    box-shadow:
      0px 18px 5px 0px rgba(0, 0, 0, 0),
      0px 12px 5px 0px rgba(0, 0, 0, 0),
      0px 7px 4px 0px rgba(0, 0, 0, 0.01),
      0px 3px 3px 0px rgba(0, 0, 0, 0.02),
      0px 1px 2px 0px rgba(0, 0, 0, 0.03);

    h6 {
      font-size: 38px;
    }

    input[type="radio"],
    input[type="checkbox"] {
      + label {
        max-width: 100%;
      }
    }

    .action {
      flex-flow: row;
      justify-content: flex-end;

      &.around {
        justify-content: space-around;
      }

      &.left {
        justify-content: flex-start;

        button {
          padding: 16px 32px;
        }
      }

      button {
        width: auto;
      }
    }

    .signature-box {
      width: 45%;
    }
  }
}
